.pdfContainer {
  display: flex;
  //align-items: center;
  justify-content: center;
  padding-top: 0.5em;
  //padding-top: 10em;

}
.navigationContainer {
  padding-top: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
}