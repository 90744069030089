@font-face {
  font-family: "HK Grotesk Extra Bold";
  src: url("HKGrotesk-ExtraBold.woff2") format("woff2"),
    url("HKGrotesk-ExtraBold.woff") format("woff");
  /* font-weight: 800; */
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk Medium";
  src: url("HKGrotesk-Medium.woff2") format("woff2"),
    url("HKGrotesk-Medium.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk Normal";
  src: url("HKGrotesk-Regular.woff2") format("woff2"),
    url("HKGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk Light";
  src: url("HKGrotesk-Light.woff2") format("woff2"),
    url("HKGrotesk-Light.woff") format("woff");
  /* font-weight: 300; */
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk Black";
  src: url("HKGrotesk-Black.woff2") format("woff2"),
    url("HKGrotesk-Black.woff") format("woff");
  /* font-weight: 900; */
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk Bold";
  src: url("HKGrotesk-Bold.woff2") format("woff2"),
    url("HKGrotesk-Bold.woff") format("woff");
  /* font-weight: bold; */
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk Semibold";
  src: url("HKGrotesk-SemiBold.woff2") format("woff2"),
    url("HKGrotesk-SemiBold.woff") format("woff");
  /* font-weight: 600; */
  font-style: normal;
}
