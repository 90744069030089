//.annotation-marker {
//  color: #ffffff;
//  line-height: 1.8;
//  text-align: center;
//  font-family: "monospace";
//  font-weight: bold;
//  position: absolute;
//  width: 25px;
//  height: 25px;
//  border-radius: 15px;
//  border: 2px solid #ffffff;
//  background: black;
//  visibility: hidden;
//  box-shadow: 5px 5px 15px 1px #000000;
//  z-index: 0;
//}
.annotation-marker {
  border-radius: 50% 50% 50% 0;
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
  color: #ffffff;
  line-height: 1.8;
  text-align: center;
  font-family: "HK Grotesk Bold";
  font-weight: bold;
  position: absolute;
  z-index: 50 !important;
  //position: relative;
  //box-shadow: 5px 5px 15px 1px rgba(30, 30, 30, 0.5);
  //z-index: 100;
}
.annotation-marker-comment {
  border-radius: 50% 50% 50% 0;
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
  background-color: #e43397;
  padding-top: 20px;
  //line-height: 1.8;
  //text-align: center;
  //font-family: "monospace";
  //font-weight: bold;
  //position: absolute;
  //box-shadow: 5px 5px 15px 1px rgba(30, 30, 30, 0.5);
  //z-index: 900000;
}

.annotation-label {
  position: absolute;
  max-width: 250px;
  min-height: 50px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background: #ffffff;
  color: #000000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 8px;
  border: #ffffff solid 2px;
  box-shadow: 5px 5px 15px 1px rgba(30, 30, 30, 0.5);
  z-index: 900000;
}
.annotation-label-oncreation {
  position: absolute;
  max-width: 250px;
  min-height: 50px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  left: 100px;
  top: 150px;
  background: #ffffff;
  color: #000000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 8px;
  border: #ffffff solid 2px;
  box-shadow: 5px 5px 15px 1px rgba(30, 30, 30, 0.5);
  z-index: 90000;
}

.annotation-label:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 8px 12px 8px 0;
  border-color: transparent white;
  display: block;
  width: 0;
  z-index: 1;
  margin-top: -11px;
  left: -12px;
  top: 20px;
}

.annotation-label:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 9px 13px 9px 0;
  border-color: transparent #ffffff;
  display: block;
  width: 0;
  z-index: 0;
  margin-top: -12px;
  left: -15px;
  top: 20px;
}

.annotation-title {
  font: normal 20px arial, serif;
  margin-bottom: 8px;
}

.annotation-desc {
  font: normal 14px arial, serif;
}
