/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  user-select: none;
}

#myCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  background: lightgray;
  /*background-image: linear-gradient(white, grey);*/
}

@tailwind base;
@tailwind components;
@tailwind utilities;