body {
  background-color: #f4f4f4;
}
.userFileDisplayOuterContainer {
  //background: #f4f4f4;
  //margin: 0 auto;
  //height: 100%;

}
.userHomeContainer {
  //margin-top: 200px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 200px 16px;
  //display: flex;
  //justify-content: space-between;
  align-items: center;
  //background: #763b3b;
  height: 100%;

}

.documentNameLabel {
  font-family: "HK Grotesk Semibold";
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  color: #6d6d6d;
  margin-bottom: 5px;
  float: left;
}

.noDocumentsNotification {
  font-family: "HK Grotesk Normal";
  font-size: 16px;
  text-align: center;
  padding-left: 20px;
  color: #6d6d6d;
  margin-bottom: 5px;
}

.documentName {
  font-family: "HK Grotesk Semibold";
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  color: #6d6d6d;
  margin-bottom: 8px;
  float: left;
  cursor: pointer;
}

.documentLabels {
  font-family: "HK Grotesk Semibold";
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  color: #6d6d6d;
  margin-bottom: 8px;
  float: right;
}

.documentContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

}
.bodyContainer {
  border-bottom: 2px solid #b3b3b3;
  margin-top: 1.5em;
}

.documentLabelsHeader {
  margin: 0 -1em;
}

.labelContainer {
  width: 40%;
  display: flex;
  justify-content: space-between;
  float:right;
  //margin: 0 1em;
}

.uploadButton {
  width: 75px;
  height: 75px;
  border: 2px solid #949494;
  border-radius: 20px;
  padding: 22px 11px 1px;
  margin-bottom: 3.5em;
  margin-left: 1em;
  text-align: center;
  cursor: pointer;
  color:#949494;


}
.uploadButton:hover {
  background-color: #e7e7e7;
}

.termsDisclaimer {
  font-family: "HK Grotesk Normal";
  font-size: 12px;
  text-align: center;
  padding: 1px 20px 20px;
  color: #6d6d6d;
  margin-bottom: 5px;
}