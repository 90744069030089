.firebaseui-container {
    /*background: rgba(0, 0, 0, 0.05);*/
    margin-bottom: 15px;
    min-height: 150px;
    padding-top: 10px;
    border-radius: 20px;
    box-shadow: none;
}
.mdl-button--primary.mdl-button--primary {
    color: #00c4cc;
}
.firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}
.firebaseui-card-header {
    /*display: none;*/
}
.firebaseui-subtitle, .firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: #00c4cc;
    border-radius: 18px;
    box-shadow: none;
}
.firebaseui-id-dismiss-info-bar {
    display: block;
}
.firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
}
.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: #121640 !important;
}

.firebaseui-link {
    color: #e43397 !important;
}

.firebaseui-button {
    border-radius: 18px;
    color: gray;
}

.mdl-button--primary.firebaseui-button {
    color: #00c4cc !important;
    border: 2px solid #00c4cc;
    border-radius: 18px;
}

.mdl-button--primary.firebaseui-button:hover {
    background-color: #fff;
    color: #00c4cc;
}
.mdl-card.firebaseui-container {
    box-shadow: none;
}

