
#treeViewContainer ul li{
  //color: blue;
  //cursor: pointer;
  //padding-left: 3px;
  padding-bottom: 5px;
}

#treeViewContainer   input[type="checkbox"]{
  transform : scale(1.2);
  margin-right: 10px;
}