$background-color: #ffffff;
$header-color: #fcfcfc;
@import "../fonts/fonts.css";

$primary: #00c4cc;
$dark: #777777;

.header {
  padding: 16px 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 61;
  background: $header-color;
  margin-top: 60px;
  height: 4.2em;

}
.bubble {
  color: $background-color;
  font-size: 0.9em;
  padding: 10px 15px;
  border-radius: 20px;
  margin-bottom: 8px;
  vertical-align: top;
  width: fit-content;
  max-width: 75%;
  font-family: "HK Grotesk Semibold";
  background: $primary;

}


.outgoing {
  padding-right: 20px;
  padding-bottom: 9px;

  clear:both;
  .bubble {
    background: $primary;
    float: right;
    text-align: right;
  }
}

.incoming {
  clear:both;
  padding-bottom: 9px;
  padding-left: 20px;

  .bubble {
    background: lighten($dark, 23%);;
  }
}

.commentBubbleOther {
  text-align: left;
  background: #d0d0d0;
  border-radius: 20px;
  padding: 10px 16px;
  margin-top: 10px;
  font-size: 15px;
  margin-left: 9px;
  width: fit-content;
  max-width: 75%;
  font-family: "HK Grotesk Normal";
}



.commentContainer {
  margin-top: 8em;
  margin-bottom: 4em;
  padding: 0 5px;
}

.commentContainerParent {
  z-index: 70000000;
}

.NotificationText {
  font-family: "HK Grotesk Normal";
  font-size: 13px;
  padding-left: 20px;
  padding-bottom: 2px;
  margin-top: 10px;
  color: #767676;
}

.revisionBubble {
  text-align: left;
  background: #dcdbdb;
  margin-top: 12px;
  margin-bottom:3px;
  color: white;
  border-radius: 20px;
  padding: 10px 16px;
  font-size: 15px;
  margin-left: 35px;
  font-family: "HK Grotesk Semibold";
  float: right;
  width: 70%;
}

.HeadingTextDrawer {
  font-family: "HK Grotesk Bold";
  font-size: 18px;
  text-align: center ;
  //padding-left: 20px;
  margin-top: 17px;
  margin-bottom: 0.5em;
  color: #4d4d4d;
}

.HeadingTextBubble {
  font-family: "HK Grotesk Semibold";
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
}

.RevisionNumberText {
  font-family: "HK Grotesk Normal";
  font-size: 10px;
  margin-top: -6px;
  color: #767676;
  text-align: right;
  margin-bottom: -6px;
}

.author {
  font-family: "HK Grotesk Normal";
  font-size: 9px;
  text-align: right;
  display: inline-block;
  position: relative;
  margin-top: -7px;
}
.timestamp {
  font-family: "HK Grotesk Normal";
  font-size: 9px;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  align-self: center;

}
.incoming {
  float: left;
}
.outgoing {
  float: right;
}